<template>
  <div>
    <TepmplateBlock
      content-class="mytechnique-wrap"
      mod-class
      title-page="Календарь"
    >
      <FullCalendar :options="calendarOptions" class="calendar" v-if="ready" />

      <div v-if="renderedEvents.length" class="day-orders-container">
        <div class="scroll-container">
          <vuescroll :ops="ops">
            <div
              v-for="(item, index) in renderedEvents"
              :key="index"
              class="event-item"
            >
              {{ item.title }}
            </div>
          </vuescroll>
        </div>
      </div>
      <div ref="calendarModal" class="calendar__modal" v-show="showModal">
        <div class="modal-header">
          <div>
            <h4>{{ eventDay }}</h4>
            <h4>{{ eventDate }}</h4>

            <span class="link c-pointer" @click="goToOrder">Перейти</span>
          </div>
          <span class="close-modal" @click="onCloseModal">x</span>
        </div>
        <div class="day-data">
          {{ modalData }}
        </div>
      </div>
    </TepmplateBlock>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import ruLocale from "@fullcalendar/core/locales/ru";
import TepmplateBlock from "../components/TepmplateBlock";
import RequestManager from "../function/request/RequestManager";
import vuescroll from "vuescroll";
import { mapState } from "vuex";

export default {
  name: "MyCalendar",
  components: {
    TepmplateBlock,
    FullCalendar,
    vuescroll
  },
  computed: {
    ...mapState("calendar", ["events", "ready"])
  },
  created() {
    this.calendarOptions.events = this.events;
    // RequestManager()
    //   .getCalendar()
    //   .then(data => {
    //     const events = data.map(elem => ({
    //       title: this.getTime(elem.timestamp) + ", " + elem.description,
    //       date: this.getDateString(elem.timestamp)
    //     }));
    //     this.events = events;
    //     const groopedEvents = Object.create(null);
    //     events.forEach(elem => {
    //       if (
    //         Object.prototype.hasOwnProperty.call(groopedEvents, elem.date) ===
    //         false
    //       ) {
    //         groopedEvents[elem.date] = { title: 1, date: elem.date };
    //       } else {
    //         groopedEvents[elem.date] = {
    //           title: groopedEvents[elem.date].title + 1,
    //           date: elem.date
    //         };
    //       }
    //     });
    //     const eventsArr = [];
    //     for (let key in groopedEvents) {
    //       eventsArr.push(groopedEvents[key]);
    //     }
    //     const calendarEvents = eventsArr.map(elem => {
    //       const title = elem.title.toString();
    //       if (
    //         title.length > 1 &&
    //         +title.slice(title.length - 2) > 10 &&
    //         +title.slice(title.length - 2) < 20
    //       ) {
    //         return { ...elem, title: elem.title + " заявок" };
    //       }
    //       if (title[title.length - 1] === "1") {
    //         return { ...elem, title: elem.title + " заявка" };
    //       }
    //       if (
    //         title[title.length - 1] === "2" ||
    //         title[title.length - 1] === "3" ||
    //         title[title.length - 1] === "4"
    //       ) {
    //         return { ...elem, title: elem.title + " заявки" };
    //       } else {
    //         return { ...elem, title: elem.title + " заявок" };
    //       }
    //     });
    //     this.calendarOptions.events = calendarEvents;
    //   });
  },
  data() {
    return {
      ops: {
        scrollPanel: {
          speed: 300,
          maxHeight: 110,
          scrollingX: false
        }
      },

      days: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
      eventDay: "",
      modalData: "",
      eventDate: "",
      rel_obj_type: "",
      rel_obj_id: "",
      showModal: false,
      // events: [],
      renderedEvents: [],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        locales: [ruLocale],
        locale: "ru",
        initialView: "dayGridMonth",
        editable: false,
        eventClick: this.handleDateClick,
        dateClick: this.onDateClick,
        // datesSet: this.onDatesSet,
        events: [],
        headerToolbar: {
          left: "",
          center: "prev title next",
          right: ""
        },
        navLinks: false
      },
      active: false
    };
  },
  mounted() {
    // const todayElem = document.querySelector(".fc-day-today");
    // const childs = document.querySelector(".fc-day-today").parentElement
    //   .childNodes;
    // childs.forEach(child => {
    //   child.style.cssText = "border-top: 2px solid #99c4d3 !important";
    // });
    // todayElem.style.cssText =
    //   "border-top: 2px solid #016b90 !important; background: #fff";
  },
  //
  methods: {
    goToOrder() {
      this.$router.push({
        name: "RequestChat",
        params: { id: this.rel_obj_id }
      });
    },
    onDatesSet(param) {
      // this.calendarOptions.events = [];
      // this.events = [];
      const todayElem = document.querySelector(".fc-day-today");
      if (todayElem !== null) {
        const childs = document.querySelector(".fc-day-today").parentElement
          .childNodes;
        childs.forEach(child => {
          child.style.cssText = "border-top: 2px solid #99c4d3 !important";
        });
        todayElem.style.cssText =
          "border-top: 2px solid #016b90 !important; background: #fff";
      }
      const queryArr = [];
      const startYear = param.start.getFullYear();
      const startMonth =
        param.start.getMonth() + 1 < 10
          ? "0" + (param.start.getMonth() + 1)
          : param.start.getMonth() + 1;
      const start = startYear + "-" + startMonth;
      const endYear = param.end.getFullYear();
      const endMonth =
        param.end.getMonth() + 1 < 10
          ? "0" + (param.end.getMonth() + 1)
          : param.end.getMonth() + 1;
      const end = endYear + "-" + endMonth;
      queryArr.push(start);
      const diff = param.end.getMonth() - param.start.getMonth();
      if (diff > 1) {
        const middleYear = param.start.getFullYear();
        const middleMonth =
          param.start.getMonth() + 2 < 10
            ? "0" + (param.start.getMonth() + 2)
            : param.start.getMonth() + 2;
        const middle = middleYear + "-" + middleMonth;
        queryArr.push(middle);
      }
      queryArr.push(end);
      queryArr.forEach(elem => {
        const data = { month: elem };
        RequestManager()
          .getCalendar(data)
          .then(data => {
            const events = data.map(elem => ({
              title: this.getTime(elem.timestamp) + ", " + elem.description,
              date: this.getDateString(elem.timestamp)
            }));
            // this.events = [...this.events, ...events];
            const groopedEvents = Object.create(null);
            events.forEach(elem => {
              if (
                Object.prototype.hasOwnProperty.call(
                  groopedEvents,
                  elem.date
                ) === false
              ) {
                groopedEvents[elem.date] = { title: 1, date: elem.date };
              } else {
                groopedEvents[elem.date] = {
                  title: groopedEvents[elem.date].title + 1,
                  date: elem.date,
                  rel_obj_type: elem.rel_obj_type,
                  rel_obj_id: elem.rel_obj_id
                };
              }
            });
            const eventsArr = [];
            for (let key in groopedEvents) {
              eventsArr.push(groopedEvents[key]);
            }
            const calendarEvents = eventsArr.map(elem => {
              const title = elem.title.toString();
              if (
                title.length > 1 &&
                +title.slice(title.length - 2) > 10 &&
                +title.slice(title.length - 2) < 20
              ) {
                return { ...elem, title: elem.title + " задач" };
              }
              if (title[title.length - 1] === "1") {
                return { ...elem, title: elem.title + " задача" };
              }
              if (
                title[title.length - 1] === "2" ||
                title[title.length - 1] === "3" ||
                title[title.length - 1] === "4"
              ) {
                return { ...elem, title: elem.title + " задачи" };
              } else {
                return { ...elem, title: elem.title + " задач" };
              }
            });
            this.calendarOptions.events = [
              ...this.calendarOptions.events,
              ...calendarEvents
            ];
          });
      });
    },
    handleDateClick(arg) {
      const date = this.getDateString(arg.event.start);
      this.renderedEvents = this.events.filter(elem => elem.date === date);
      this.eventDay = this.days[arg.event.start.getDay()].toUpperCase();
      this.showModal = true;
      this.eventDate = arg.event.start.getDate();
      this.$refs.calendarModal.style.top = arg.jsEvent.clientY + "px";
      this.$refs.calendarModal.style.left = arg.jsEvent.clientX - 100 + "px";
      this.modalData = arg.event._def.title;
      this.rel_obj_type = arg.event._def.extendedProps.rel_obj_type;
      this.rel_obj_id = arg.event._def.extendedProps.rel_obj_id;
    },
    getDateString(date) {
      return (
        new Date(date).getFullYear() +
        "-" +
        (new Date(date).getMonth() + 1) +
        "-" +
        new Date(date).getDate()
      );
    },
    getTime(date) {
      const hours = new Date(date).getHours();
      let minutes = new Date(date).getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      return hours + " : " + minutes;
    },
    onCloseModal() {
      this.showModal = false;
      this.modalData = "";
      this.rel_obj_id = "";
      this.rel_obj_type = "";
    }
  }
};
</script>
<style scoped lang="scss">
.scroll-container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  max-height: 100%;
  .__vuescroll {
    max-height: 20% !important;
  }
  .__view {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
  }
}
.event-item {
  border-left: 5px solid #51a2be;
  border-radius: 4px 0 0 4px;
  border-left: 7px solid #3788d8;
  padding: 10px;
  margin: 20px;
  margin-left: 0;
  max-width: 24%;
  max-height: 80px;
  cursor: pointer;
}
.day-orders-container {
  width: 100%;
  max-height: 180px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.fc.fc-scroller {
  overflow: hidden !important;
}
.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-past {
  display: block;
  height: 100%;
}
.fc-event-main::before {
  min-height: 28px;
  height: 100%;
}
.fc-event-title-container {
  min-height: 28px;
}
.fc-event-main-frame {
  .fc-event-title.fc-sticky {
    cursor: pointer;
    margin-left: 12px !important;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    background: var(--main-card-color);
  }
}

.calendar {
  position: relative;
  margin-top: 17px;
  padding: 35px 16px 0;
  width: 100%;
  overflow: hidden;
  height: 650px;
  background-color: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  border: 0 !important;
}
.calendar__modal {
  position: absolute;
  top: 0;
  min-width: 250px;
  z-index: 555;
  background-color: var(--main-card-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  .modal-header {
    display: flex;
    position: relative;
    justify-content: center;
    border-bottom: 0;
    h4 {
      width: 100%;
      text-align: center;
    }
    .close-modal {
      cursor: pointer;
      font-size: 20px;
      font-weight: 700;
      position: absolute;
      right: 10px;
      top: 0;
    }
  }
  .day-data {
    width: 100%;
    border-left: 5px solid #51a2be;
    border-radius: 4px 0 0 4px;
    border-left: 7px solid #3788d8;
    padding: 10px;
    margin: 20px;
  }
}
.link {
  color: blue;
  text-decoration: underline;
}

.c-pointer {
  cursor: pointer;
}
</style>
